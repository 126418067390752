import { LinksBlock } from 'libs/brand-system/src/templates/LinksBlock';
import { textTitle1 } from 'libs/growth-platform-brand-system-v2/src/styles/typography';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import {
  clientSectionGenerator,
  learningHubSectionGenerator,
  ourProductSectionGenerator,
  resourceSectionGenerator,
} from './logic';

export const Head = ({ data, pageContext, location }: any) => (
  <HeadWrapper
    data={data}
    pageContext={pageContext}
    location={location}
    templateName="Sitemap"
    dataName={'datoCmsSitemapPage'}
    largestContentfullPaintImg={null}
  />
);
export const query = graphql`
  query DatoSitemapPage($locale: String, $id: String) {
    datoCmsSitemapPage(locale: $locale, id: { eq: $id }) {
      ...SitemapPage
    }
    datoCmsFooter(locale: $locale) {
      ...Footer
    }
    allDatoCmsBlogArticle(
      locale: $locale
      filter: { locales: { eq: $locale } }
    ) {
      nodes {
        subCategory {
          slug
          hero {
            title
          }
          category {
            slug
            hero {
              title
            }
          }
        }
        metaFields {
          title
        }
        slug
      }
    }
    allDatoCmsSubCategoryPage(
      locale: $locale
      filter: { locales: { eq: $locale } }
    ) {
      nodes {
        slug
        category {
          slug
        }
        hero {
          title
        }
      }
    }
    allDatoCmsCategoryPage(
      locale: $locale
      filter: { locales: { eq: $locale } }
    ) {
      nodes {
        slug
        hero {
          title
        }
      }
    }
    datoClients: allDatoCmsClientPage(locale: $locale) {
      nodes {
        ...ClientPage
      }
    }
    hubspotResources: allHubspotLanding(
      filter: {
        currentState: { eq: "PUBLISHED" }
        language: { eq: $locale }
        templatePath: { eq: "payfit/templates/content-landing-page.html" }
      }
    ) {
      edges {
        node {
          ...HubspotLandingPage
        }
      }
    }
    resourceSections: datoCmsAllResourcesPage(locale: $locale) {
      toolboxSections {
        label
        relatedKeywords
      }
    }
    allClientsSlug: datoCmsAllClientsPage(locale: $locale) {
      slug
    }
    allResourcesSlug: datoCmsAllResourcesPage(locale: $locale) {
      slug
    }
    blogHomePageSlug: datoCmsBlogHome(locale: $locale) {
      slug
    }
  }
`;

const Sitemap = ({ data, pageContext }: any) => {
  const page: any = data.datoCmsSitemapPage;
  page.simplifiedFooter = true;

  if (!page) return <></>;

  // This is the first section, directly retrieve from the footer: product and company
  // TODO: add pillar pages children
  const ourProductSection: any = ourProductSectionGenerator(
    data?.datoCmsFooter?.sections?.[0],
    data?.datoCmsFooter?.sections?.[1],
    page.firstSectionTitle,
    pageContext.locale,
  );

  // This is the second section: the learning hub articles
  const learningHubSection = learningHubSectionGenerator(
    data.allDatoCmsCategoryPage.nodes,
    data.allDatoCmsSubCategoryPage.nodes,
    data.allDatoCmsBlogArticle.nodes,
    page.secondSectionTitle,
    data.blogHomePageSlug.slug,
    pageContext.locale,
  );

  // This is the third section: the resources
  const resourceSection: any = resourceSectionGenerator(
    data.resourceSections.toolboxSections,
    data.hubspotResources.edges,
    data.allResourcesSlug.slug,
    page.thirdSectionTitle,
    pageContext.locale,
  );

  // This is the fourth section: the clients
  const clientSection: any = clientSectionGenerator(
    data.datoClients.nodes,
    page.fourthSectionTitle,
    data.allClientsSlug.slug,
    pageContext.locale,
  );

  return (
    <Layout
      pageContext={pageContext}
      data={page}
      customHeader={page.customMenu}
      templateName="Sitemap"
    >
      <div
        className={classNames(
          textTitle1,
          'container pt-40 mt-96 font-medium text-neutral-301',
        )}
      >
        <h1>{page.title}</h1>
      </div>
      {ourProductSection.categories.length > 0 && (
        <LinksBlock {...ourProductSection} />
      )}
      {learningHubSection.categories.length > 0 && (
        <LinksBlock {...learningHubSection} />
      )}
      {resourceSection.categories.length > 0 && (
        <LinksBlock {...resourceSection} />
      )}
      {clientSection.categories.length > 0 && <LinksBlock {...clientSection} />}
    </Layout>
  );
};

export default Sitemap;
